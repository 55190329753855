<template>
    <p-form @submit="onSubmit">
        <div class="container-fluid content-wrapper" v-if="product">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Book</div>
                </b-col>
                <b-col class="text-right">
                    <p-button type="submit" :is-busy="isBusy" variant="primary"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
            <b-tabs justified id="variants" v-model="tabIndex">
                <b-tab title="New & Used">
                    <NewUsedBookTab
                        :product="product"
                        :parameters="this.parameters"
                        :is-new="isNew"
                        @deleteProduct="deleteProduct"
                        @savePage="onPrintLabelClicked"
                    />
                </b-tab>
                <b-tab
                    title="Rentals"
                    v-if="product.rentalData && (product.rentalData.newRentalItemId > 0 || product.rentalData.usedRentalItemId > 0) "
                >
                    <RentalsTab
                        :product="product"
                        :rental-data="product.rentalData"
                    />
                </b-tab>
                <b-tab title="Digital" v-if="product['digitalVariant']">
                    <DigitalTab
                        :product="product"
                        :parameters="this.parameters"
                        @deleteProduct="deleteProduct"
                    />
                </b-tab>
            </b-tabs>
            <b-row class="mt-4">
                <b-col class="text-center mb-3">
                    <p-button
                        variant="primary"
                        type="submit"
                        :is-busy="isBusy"
                        class="text-right"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </div>
    </p-form>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import NewUsedBookTab from './NewUsedBookTab.vue';
import DigitalTab from './DigitalTab.vue';
import RentalsTab from './RentalsTab.vue';
import selectListOptionsDataContext from "@/services/selectListOptions.dataContext.js";

export default {
    mixins: [NavigationGuard],
    props: {
        id: String
    },
    data() {
        return {
            product: null,
            parameters: null,
            barcodes: null,
            isBusy: false,
            type: 2,
            tabIndex: 0,
            bookVariants: ['new', 'used', 'digital', 'trade'],
            isPrintLabelClicked: false,
            productType: String
        };
    },
    watch: {
        product: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.id === 'new';
        },
        activeVariants() {
            return this.bookVariants
                .filter(x => this.product[`${x}Variant`] != null)
                .map(x => this.product[`${x}Variant`]);
        }
    },
    methods: {
        deleteProduct({ variant, type }) {
            if (type == 'new' && this.product.usedVariant != null) {
                this.$toasted.global.app_error(
                    'Must delete used book before deleting new.'
                );
                return;
            } 
            let formDirtyIsBeforeDelete = this.formIsDirty;
            this.$bvModal
                .msgBoxConfirm(
                    'Are you sure you want to delete this product?',
                    {
                        okTitle: 'Delete',
                        okVariant: 'danger'
                    }
                )
                .then(value => {
                    if (value) {
                        this.isBusy = true; 
                        axios
                            .post('products/delete', {sku:[variant.sku]}) 
                            .then(resp => {
                                if (resp) {
                                    this.product[`${type}Variant`] = null;
                                    this.$toasted.global
                                        .app_success(
                                            `SKU ${variant.sku} deleted successfully.`
                                        )
                                        .goAway(5000);
                                    if (this.activeVariants.length < 1) {
                                        this.dataSaved();
                                        this.$router.push('/products');
                                    } else if (!formDirtyIsBeforeDelete) {
                                        this.dataLoadedCalled();
                                    }
                                }
                            })
                            .finally(() => {
                                this.isBusy = false;
                            });
                    }
                });
        },

        onSubmit() {
            if (
                this.product.newVariant == null &&
                this.product.usedVariant == null &&
                this.product.tradeVariant == null
            ) {
                this.$toasted.global.app_error(
                    'You must add a new, used, or trade variant before saving.'
                );
                this.isPrintLabelClicked = false;
                return;
            }
            if (
                (this.product.newVariant &&
                    this.product.newVariant.cost == null) ||
                (this.product.tradeVariant &&
                    this.product.tradeVariant.cost == null)
            ) {
                this.$toasted.global.app_error(
                    'You must add a cost before saving.'
                );
                this.isPrintLabelClicked = false;
                return;
            }
            if (
                (this.product.newVariant &&
                    this.product.newVariant.margin == null) ||
                (this.product.tradeVariant &&
                    this.product.tradeVariant.margin == null)
            ) {
                this.$toasted.global.app_error(
                    'You must add a margin before saving.'
                );
                this.isPrintLabelClicked = false;
                return;
            }
            if (
                (this.product.newVariant &&
                    this.product.newVariant.retail == null) ||
                (this.product.tradeVariant &&
                    this.product.tradeVariant.retail == null)
            ) {
                this.$toasted.global.app_error(
                    'You must add a retail before saving.'
                );
                this.isPrintLabelClicked = false;
                return;
            }

            let hasZeroCost = this.bookVariants.some(
                v => this.product[`${v}Variant`]?.cost === 0
            );
            if (hasZeroCost) {
                this.$bvModal
                    .msgBoxConfirm(
                        'About to save item with a $0.00 cost. Do you wish to continue?'
                    )
                    .then(value => {
                        if (!value) {
                            return;
                        }
                        this.saveProduct();
                        return;
                    });
            } else {
                this.saveProduct();
                return;
            }

            // todo error handling
        },

        async saveProduct() {
            //submit product save after validation has passed

            this.isBusy = true;
            this.product.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            axios
                .post('products/tx', this.product)
                .then(response => {
                    if (!this.isNew) {
                        this.product = response.data;
                    }
                    this.dataLoadedCalled();
                    if (this.isNew) {
                        let sku = response.data.newVariant
                            ? response.data.newVariant.sku
                            : response.data.usedVariant
                            ? response.data.usedVariant.sku
                            : response.data.tradeVariant.sku;
                        this.$router.push('/products/tx/' + sku);
                    }
                    this.$toasted.global
                        .app_success(
                            `Product '${this.product.title}' saved successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                    if(this.isPrintLabelClicked)this.$bvModal.show('printLabelModal'+this.productType);
                    this.isPrintLabelClicked = false;
                });
        },
        async loadData() {            
            let locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();

            let dataPromise = this.isNew
                ? axios.get('products/book/new')
                : axios.get('products/book', { params: { id: this.id, locationId: locationId } });
            let paramPromise = axios.get('products/bookparameters');
            await axios.all([dataPromise, paramPromise]).then(
                axios.spread(async (dataResp, paramResp) => {
                    this.parameters = paramResp.data;
                    this.product = dataResp.data;

                    if (this.product.digitalVariant?.sku == this.id) {

                        this.tabIndex = this.product.rentalData!=null? 2:1;
                    }

                    this.barcodes = {};
                    if (this.product.newVariant != null) {
                        this.barcodes.newTextbook = this.product.newVariant.barcodes;
                    }
                    if (this.product.usedVariant != null) {
                        this.barcodes.usedTextbook = this.product.usedVariant.barcodes;
                    }
                    if (this.product.tradeVariant != null) {
                        this.barcodes.tradebook = this.product.tradeVariant.barcodes;
                    }
                    if (!this.isNew) {
                        if (this.product.type != this.type) {
                            this.$router.push('/products');
                        }
                    }
                    if (this.isNew) {
                        this.product.bindingId =
                            paramResp.data.defaultBindingId;
                        //await this.addVariant('new');
                    }

                    if (!this.isNew) {
                            this.dataLoadedCalled();
                    }
                })
            );
        },
        onPrintLabelClicked(type){
             this.productType = type;
             if (!this.formIsDirty){
                this.$bvModal.show('printLabelModal'+this.productType);
             }else{
                this.isPrintLabelClicked = true;
                this.onSubmit();
             }
        },
        dataLoadedCalled(){
            setTimeout(() => {
                this.dataLoaded();
            }, 500);
        }
    },
    mounted: async function() {
        await this.loadData();
    },
    components: {
        DigitalTab,
        NewUsedBookTab,
        RentalsTab
    }
};
</script>
