<template>
    <p-modal name='StockAdjustmentModal' label='Stock Adjustment' size='lg' @ok='handleModalOk' @hidden='handleModalHidden'>
        <p-input readonly label='Sku' v-model='stockAdjustment.sku' />
        <p-number label='Qty' placeholder='(eg -5, 5 )' v-model='stockAdjustment.qty' input-type='integer' left-align />
        <p-select
            v-model='stockAdjustment.reason'
            data-type='stockAdjustmentReasons'
            :disabled='stockAdjustment.sku === null'
            text-field='value'
            label='Reason'
        />
        <p-text-area
            v-model='stockAdjustment.comment'
            :disabled='stockAdjustment.sku === null'
            label='Comment'
            rules="max:2000"
        />
    </p-modal>
</template>

<script>
import axios from 'axios';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    name: 'StockAdjustment',
    props: {
        adjustmentData: {}
    },
    data() {
        return {
            stockAdjustment: {
                qty: 0,
                sku: 0,
                cost: 0,
                retail: 0,
                reason: 'Count Adjustment',
                comment: null,
                locationId: null
            }
        };
    },
    watch: {
        adjustmentData: {
            handler: function() {
                this.stockAdjustment.sku = this.adjustmentData.sku;
                this.stockAdjustment.cost = this.adjustmentData.cost;
                this.stockAdjustment.retail = this.adjustmentData.retail;
            },
            deep: true
        }
    },
    methods: {
        async handleModalOk() {
            if (
                this.stockAdjustment.sku !== null &&
                this.stockAdjustment.qty !== 0
            ) {
                this.stockAdjustment.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
                axios.post('stockAdjustment/', this.stockAdjustment);
            }
            this.$emit('ok', this.stockAdjustment);
        },
        handleModalHidden() {
            this.stockAdjustment.qty = 0;
            this.stockAdjustment.reason = 'Count Adjustment';
            this.stockAdjustment.comment = null;
        }
    }
};
</script>