<template>
    <div class="container-fluid content-wrapper" v-if="purchaseOrder">
        <p-form @submit="onSubmit()" id="purchase-order-form" ref="purchaseOrderForm" v-on:deletePurchaseOrder="deletePurchaseOrder(this)">
            <b-row class="content-heading">
                <b-col>
                    <div >
                        {{ isNew ? 'Add' : 'Edit' }} Purchase Order
                    </div>
                </b-col>
                <b-col>
                    {{ isSingleRecord ? '' : `(${index + 1}/${this.ids.length})` }}
                </b-col>
            </b-row>
            <b-row class="pb-2" cols="2" cols-lg="12">
                <b-col>
                    <p-button :is-busy="isBusy"
                              v-if="!isSingleRecord"
                              @click="previous"
                              :disabled="first"
                              variant="outline-primary">&#8592; Previous</p-button>
                </b-col>
                <b-col class="text-right">
                    <p-button :is-busy="isBusy"
                              v-if="!isSingleRecord"
                              @click="next"
                              variant="outline-primary"
                              class="mb-2">
                        {{
                            showDoneButton ? 'Done' : `Next &#8594;`
                        }}
                    </p-button>
                </b-col>
            </b-row>
            <b-tabs justified>
                <b-tab title="Purchase Order">
                    <PurchaseOrder :purchase-order="purchaseOrder"
                                   :is-busy='isBusy'
                                   :is-new="isNew"
                                   @date-picker-initialized="e => {dataLoaded()}"
                                   @deletePurchaseOrder="deletePurchaseOrder(purchaseOrder)" />
                </b-tab>
                <b-tab title="Invoices" :disabled="isNew">
                    <Invoices :disabled="isNew"
                              :purchase-order="purchaseOrder"
                              @date-picker-initialized="e => {dataLoaded()}"
                              :is-busy="isBusy"
                              v-if='afterLoad' />
                </b-tab>
                <b-tab title="Returns" :disabled="isNew">
                    <CreditRequests :disabled="isNew"
                                    :purchase-order="purchaseOrder"
                                    :is-busy="isBusy"
                                    v-if='afterLoad' />
                </b-tab>
            </b-tabs>
            <b-row>
                <b-col class=" mt-3 text-left">
                    <p-button :is-busy="isBusy"
                              v-if="!isSingleRecord"
                              @click="previous"
                              :disabled="first"
                              variant="outline-primary">&#8592; Previous</p-button>
                </b-col>
                <b-col class=" mt-3 ml-5 text-center">
                    <p-button variant="primary" type="submit" form="purchase-order-form" :is-busy="isBusy">Save</p-button>
                </b-col>
                <b-col class=" mt-3 text-right">
                    <p-button :is-busy="isBusy"
                              v-if="!isSingleRecord"
                              @click="next"
                              variant="outline-primary"
                              class="mb-2">
                        {{
                            showDoneButton ? 'Done' : `Next &#8594;`
                        }}
                    </p-button>
                </b-col>


            </b-row>
        </p-form>
    </div>
</template>

<script>
import PurchaseOrder from './PurchaseOrder.vue';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import Invoices from './Invoices.vue';
import CreditRequests from './CreditRequests.vue';
import axios from 'axios';
import pricing from "@/services/PricingCalculations.js"
import editPage from '@/components/mixins/EditPage';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    mixins: [NavigationGuard, editPage],
     props: {
        ids: {
             type: [Array],
            default() {
                return [];
            }
         },
    },
    data() {
        return {
            isBusy: false,
            purchaseOrder: null,
            afterLoad: false,
            index:0
        };
    },
    watch: {
        purchaseOrder: NavigationGuard.$watcher
    },
    methods: {
        async onSubmit() {
            this.isBusy = true;
            if (!(await this.$refs.purchaseOrderForm.validate())) {
                this.$toasted.global.app_error(
                        'Please correct the errors before saving.'
                    );
                this.isBusy = false;
                return;
            }
            await this.save();
            this.isBusy = false;
        },
       async save() { 
            if (this.purchaseOrder.invoices.some(i => i.invoiceDetails.length === 0)) {
                this.$toasted.global.app_error(
                    'Purchase order invoices must have at least one product added before saving'
                );
                return;
            }

            if (this.purchaseOrder.status != 'Proposed') {
                if (this.purchaseOrder.purchaseOrderDetails.length < 1) {
                    this.$toasted.global.app_error(
                        'Purchase order must have at least one product added before saving'
                    );
                    return;
                }
            }
            if (this.purchaseOrder.purchaseOrderDetails.every(x => (x.receivedQty + x.cancelQty) >= x.qty) && this.purchaseOrder.status !== 'Closed') {
                if (this.purchaseOrder.purchaseOrderDetails.some(x => x.qty > 0) && !this.purchaseOrder.purchaseOrderDetails.some(x => x.backOrderQty > 0)) {
                    this.$toasted.global.app_success(`All products are recevied or cancelled successfully. Status is changed to closed`).goAway(5000);
                    this.purchaseOrder.status = 'Closed'
                }
            }
            if (this.purchaseOrder.purchaseOrderDetails.some(i => i.qty === null)) {
                this.$toasted.global.app_error(
                    'All products on the purchase order must have their qty set before saving'
                )
                return;
            }
            if (this.purchaseOrder.purchaseOrderDetails.some(i => i.cost === null)) {
                this.$toasted.global.app_error(
                    'All products on the purchase order must have their cost set before saving'
                )
                return;
            }
            if (this.purchaseOrder.purchaseOrderDetails.some(i => i.margin === null)) {
                this.$toasted.global.app_error(
                    'All products on the purchase order must have their margin set before saving'
                )
                return;
            }
            if (this.purchaseOrder.purchaseOrderDetails.some(i => i.retail === null)) {
                this.$toasted.global.app_error(
                    'All products on the purchase order must have their retail set before saving'
                )
                return;
            }

            if (this.purchaseOrder.creditRequests.some(i => i.creditRequestDetails && i.creditRequestDetails.length === 0)) {
                this.$toasted.global.app_error(
                    'Credit Requests order must have at least one product added before saving'
                );
                return;
            }

            this.isBusy = true;

            if (this.purchaseOrder.originalStatus == 'Open' && this.purchaseOrder.status == 'Proposed') {
                this.purchaseOrder.purchaseOrderDetails.forEach(x => { x.cancelQty = 0, x.receivedQty = 0 });
            }

            this.purchaseOrder.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
          
           let response = await axios.post('purchaseOrders', this.purchaseOrder);
                if (this.isNew) {
                    this.dataSaved();
                    this.$router.push("/purchaseorders/" + response.data.purchaseOrderId);
                }


                this.purchaseOrder = response.data
                this.purchaseOrder.invoices.forEach((y) => {
                    y.invoiceDetails.forEach((x) => {
                        x.margin = pricing.computeMargin(x.cost, x.retail);
                        let pod = this.purchaseOrder.purchaseOrderDetails.find((p) => p.sku === x.sku && p.termId === x.termId && x.purchaseOrderId === this.purchaseOrder.purchaseOrderId );
                        if(pod){
                            x.purchaseOrderDetail = pod;
                            x.totalInvoicedQty = pod.receivedQty;
                        }
                    })});
                 if (!this.isNew) {
                         this.dataLoaded();
                    }
                this.$toasted.global.app_success(`Purchase order '${this.purchaseOrder.poNumber}' saved successfully.`).goAway(5000);
        },
        deletePurchaseOrder(purchaseOrder) {
            this.$bvModal
                .msgBoxConfirm(
                    'Are you sure you want to delete this Purchase Order?',
                    {
                        okTitle: 'Delete',
                        okVariant: 'danger'
                    }
                )
                .then(value => {
                    if (value) {
                        purchaseOrder.isBusy = true;
                        axios
                            .post('purchaseOrders/delete', {
                                purchaseOrderIds: [ purchaseOrder.purchaseOrderId ]
                            })
                            .then(() => {
                                this.dataSaved();
                                this.$router.push('/purchaseorders');
                                this.$toasted.global
                                    .app_success(`Purchase Order deleted successfully.`)
                                    .goAway(5000);
                            })
                            .finally(() => {
                                purchaseOrder.isBusy = false;
                            });
                    }
                });
        },
        loadData() {
            var purchaseOrderId = this.id;
            if (this.ids && this.ids.length) {
                purchaseOrderId = this.ids[this.index];
            }
            var locationId = this.$store.getters.getSelectedLocationId;
            let promise = this.isNew
                ? axios.get('purchaseOrders/new', { params: { locationId: locationId } })
                : axios.get('purchaseOrders', { params: { id: purchaseOrderId } });

            promise.then(resp => {
                this.purchaseOrder = resp.data;

                if (this.purchaseOrder.invoices != null && this.purchaseOrder.invoices.length > 0)
                {
                    this.purchaseOrder.invoices.forEach((i) => {
                        i.invoiceDetails.forEach((x) => {
                            x.margin = pricing.computeMargin(x.cost, x.retail);
                        });
                        i.invoiceDetails.forEach((x) => {
                            let pod = this.purchaseOrder.purchaseOrderDetails.find((p) => p.sku === x.sku && p.termId === x.termId && x.purchaseOrderId === this.purchaseOrder.purchaseOrderId );

                            if(pod){
                                x.purchaseOrderDetail = pod;
                                x.totalInvoicedQty = pod.invoicedQty;
                            }
                        })
                    });
                }

               if (!this.isNew) {
                         this.dataLoaded();
                    }
                setTimeout(() => {
                    this.afterLoad = true;
                }, 500);
            });
        },
         next: async function() {
            this.isBusy = true;
            try {
                if (this.done) {
                    this.$router.push('/purchaseorders');
                    return;
                }
                try {
                    await this.save();
                } catch {
                    return;
                }
               this.index++;
                if (this.done) {
                    this.$router.push('/purchaseorders');
                    return;
                }
                this.$router.push({name: 'purchaseorders', params: {id : this.ids[this.index] , ids: this.ids}});
            } finally {
                this.isBusy = false;
            }
        },
         previous: async function() {
            if (this.first) {
                return;
            }

            this.isBusy = true;
            try {
                try {
                    await this.save();
                } catch {
                    return;
                }
                this.index--;
                this.$router.push({name: 'purchaseorders', params: {id : this.ids[this.index], ids: this.ids}});
            } finally {
                this.isBusy = false;
            }
        },
    },
    mounted: function () {
        this.loadData();
        },

      computed: {
          done() {
            return !this.isSingleRecord && this.index === this.ids.length;
        },
          showDoneButton() {
            return !this.isSingleRecord && this.index === this.ids.length - 1;
        },
        first() {
            return this.index === 0;
        },
          isSingleRecord() {
              return !this.ids || this.ids.length <= 1;

        },
        },
    components: {
        PurchaseOrder,
        Invoices,
        CreditRequests
    }
};
</script>
