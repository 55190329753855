<template>
        <div>
            <p-card :title="`${label} Import`">
                <b-row>
                    <b-col>
                        <vue-csv-import
                            :map-fields="headers"
                            :headers="headers"
                            :key="'import' + importCount"
                            :can-run-no-parse="canRunNoParse"
                            :is-job-running-now="isJobRunningNow"
                            @unmapped-fields="unMappedFields = $event"
                            auto-match-fields
                            auto-match-ignore-case
                            @input="fileLoaded"
                            @loading-new-file="loadingNewFile"
                            @error-loading-file="handleFileLoadError"
                            @run-no-parse="handleRunNoParse"
                        ></vue-csv-import>
                    </b-col>
                    <b-col cols="auto" class="ml-auto">
                        <p-button @click="downloadSample">Download Sample CSV</p-button>
                    </b-col>
                </b-row>
                <b-row v-if="unMappedFields.length > 0">
                    <b-col>
                        Unable to parse file due to missing column(s):
                    <ul>
                        <li v-for="(header, index) in unMappedFields" :key="'missingField' +index">{{header}}</li>
                    </ul>
                    </b-col>
                </b-row>
                <b-row v-if="hasCaseConversionFields">
                    <b-col>
                        <p-checkbox v-model='convertCase' :label="convertCaseLabel" />
                    </b-col>
                </b-row>
            </p-card>
            <p-card :title="`Validated (${validRows.length})`" v-show='showTables' collapse-enabled title-class="text-success">
                <b-row v-if="importNotes.length > 0  && invalidRows.length < 1">
                    <b-col>
                    <ul>
                        <li v-for="(note, index) in importNotes" :key="'notes' +index" >{{note}}</li>
                    </ul>
                    </b-col>
                </b-row>
                <p-table :items="validRows"
                        :fields="fields"
                        :per-page="20"
                        :enable-row-selection='false'
                >
                </p-table>
            </p-card>
            <b-row v-show='showEditOptions' cols="1" cols-sm="2" cols-xl="4">
                <b-col>
                    <p-select v-model="editAll" label="Warning/Error Edit Mode"  :options="editOptions" />
                </b-col>
            </b-row>
            <template v-for="(validationMode) in validationModes">
                <p-card :key="validationMode.name"
                            :title-class="validationMode.titleClass"
                            :title="`${validationMode.label} (${validationMode.items.length})`"
                            :start-expanded="validationMode.startExpanded"
                            v-show='showTables'
                            collapse-enabled>
                    <p-form :ref="validationMode.name">
                        <p-table 
                            :class='validationMode.name'
                            :items="validationMode.items"
                            :fields="errorFields"
                            :per-page="5"
                            :empty-text="validationMode.emptyText"
                            :enable-row-selection="validationMode.isError == true"
                            @context-changed="validateInvalidForm"
                            :selection-actions="!validationMode.isError ? [] : [{
                                            label: 'Exclude selection from import',
                                            action: excludeFromImport
                                        },
                                        {
                                            label: `Reinstate ${excludedRows.length} excluded row(s)`,
                                            action: undoExcludeFromImport,
                                            noSelectionRequired: true,
                                            hidden: excludedRows.length < 1,
                                        },
                                        {
                                            label: `Download ${excludedRows.length} excluded row(s)`,
                                            action: downloadExcludedRows,
                                            noSelectionRequired: true,
                                            hidden: excludedRows.length < 1,
                                        }

                                    ]"
                        >
                            <template  v-for="(currentfield, index) in fields" v-slot:[`cell(${currentfield.key})`]="{ item, value, field }">
                                <div :key="index" >
                                <template v-if="enableEdit(field, item)">
                                    <p-datepicker v-if="field.inputType === 'date'"
                                        v-model="item[field.key]"
                                        :name="field.label"
                                        :validation='true'
                                        @change="validateInvalidForm();"
                                        :rules="field.addRowDataToRule ? addRowDataToRule(field.addRowDataToRule, field.rules, item) : field.rules"
                                        compact-format>
                                    </p-datepicker>
                                    <p-select v-else-if="field.dataType"
                                        v-model="item[field.key + 'Id']"
                                        :name="field.label"
                                        :validation='true'
                                        :filter="field.selectOptionsFilter ? field.selectOptionsFilter(item) : null"
                                        @change="validateInvalidForm();"
                                        :add-empty-option='includeEmptyOption(field.rules)'
                                        @selectedObject="setDropDownTextValue(item, $event, field);"
                                        :add-invalid-option="{value:-1, text:item.originalData[field.key], abbreviation:item.originalData[field.key], disabled:true}"
                                        :rules="field.rules"
                                        compact-format
                                        :data-type="field.dataType">
                                        <template v-slot:error-message name="error-message">Please select a valid {{field.label || field.key}}</template>
                                    </p-select>
                                    <p-input v-else
                                        :name="field.label"
                                        compact-format
                                        @change="validateInvalidForm"
                                        v-model="item[field.key]"
                                        :rules="field.addRowDataToRule ? addRowDataToRule(field.addRowDataToRule, field.rules, item) : field.rules"
                                    />
                                </template>
                                <template v-else>
                                    <template v-if="field.inputType === 'date'">
                                        {{dateFormatter(value)}}
                                    </template>
                                    <template v-else>
                                        {{ value }}
                                    </template>
                                </template>
                                </div>
                            </template>
                        </p-table>
                        <b-row>
                            <b-col class="mt-3 text-center">
                                <p-button
                                    :is-busy="isBusy"
                                    variant="outline-primary"
                                    @click="validateData"
                                    >Revalidate</p-button
                                >
                            </b-col>
                        </b-row>
                    </p-form>
                </p-card>
            </template>


            <b-row  v-show='showTables'>
                <b-col class="text-center">
                    <p-button :is-busy="isBusy" @click="submit" :disabled='invalidRows.length > 0' :variant="invalidRows.length === 0 && warningRows.length === 0 ? 'primary' : 'outline-primary' "
                        >Import</p-button
                    >
                </b-col>
            </b-row>
        </div>
</template>

<script>
import $ from 'jquery';
import VueCsvImport from './VueCsvImport.vue';
import Vue from 'vue';
import Axios from 'axios';
import {downloadFileToBrowser} from "@/components/Common/BrowserDownload.js";
import StoreListManager from "@/components/mixins/StoreListManager.js";
import moment from 'moment';
import {dateFormatter, listToStringFormatter} from "@/components/Common/Formatters.js";

import SharedValidation from './SharedValidation.js';

export default {
    inheritAttrs: false,

    mixins: [StoreListManager, SharedValidation],
    computed: {
        errorFields() {
            return this.fields;
        },
        validationModes() {
            return[
                {
                    label:'Warnings',
                    name:'warningForm',
                    startExpanded : false,
                    emptyText:"All data passed warning checks.",
                    items: this.warningRows,
                    titleClass: 'text-warning'
                },
                {
                    label:'Errors',
                    isError : true,
                    name:'invalidForm',
                    startExpanded : true,
                    emptyText: "All data passed validation.  Review data to be imported, then click Import to proceed.",
                    items: this.invalidRows,
                    titleClass: 'text-danger'
                }
            ]
        },
        showEditOptions() {
            return this.showTables && this.validationModes.some(x => x.items?.length > 0)
        },
        headers() {
            return this.fields.map(x => x.key);
        },
        numberProperties(){
            //columns that need to be converted to numbers
            return this.fields.filter(f => f.convertToNumber);
        },
        hasCaseConversionFields(){
            return this.fields.some(f => f.canConvertCase);
        },
        convertCaseLabel(){
            let fields = this.fields.filter(f => f.canConvertCase)
            .map(x => x.key)
            return `Convert ${listToStringFormatter(fields, 'and')} values to title case on import.`
        },
        dateProperties(){
            //columns that send dates to the api
            return this.fields.filter(f => f.inputType === 'date');
        },
        warningDefaultProperties(){
            //columns with warning level validation that have defaults if validation fails
            return this.fields.filter(f => f.warningDefault !== undefined);
        },
        idProperties(){
            //properties that need to match an id (states matching exsting drop down options)
            return this.fields.filter(f => f.dataType);
        }
    },
    props:{
        fields: {
            type: Array,
            required: true
        },
        sampleNotes: {
            type: Array
        },
        dataType: {
            type: String,
            required: true
        },
        importMode: {
            type: String,
        },
        importNotesGenerator: {
            type: Function
        },
        label: {
            type: String,
            required: true
        },
        canRunNoParse: {
            type: Boolean,
            default: false
        },
        isJobRunningNow: {
            type: Boolean,
            default: false
        }
    },
    components: {
        VueCsvImport
    },

    methods: {
        dateFormatter: dateFormatter,
        excludeFromImport(selection){
            this.excludedRowsSaved = false;
            selection.forEach((x) => {
                this.invalidRows.splice(this.invalidRows.indexOf(x), 1);
                this.uploadedData.splice(this.uploadedData.indexOf(x), 1);
                this.excludedRows.push(x);
            });

        },
        undoExcludeFromImport(){
            this.uploadedData.push(...this.excludedRows);
            this.excludedRows = [];
            this.validateData();
        },
        downloadExcludedRows(){
            let data = this.fields.map(x => x.key).join(',') + ',' + 'IsErrorOrWarning'
            this.excludedRows.forEach((row) => {
                data = data + '\n' + this.fields.map(field => {
                    if(row.originalData[field.key] === null){
                        return "";
                    }
                    return `"${row.originalData[field.key]}"`;
                }).join(',') + ',' + 'Error'
            })

            this.warningRows.forEach((row) => {
                data = data + '\n' + this.fields.map(field => {
                    if(row.originalData[field.key] === null){
                        return "";
                    }
                    return `"${row.originalData[field.key]}"`;
                }).join(',') + ',' + 'Warning'
            })
            downloadFileToBrowser(data, this.dataType + "_Problem_Import_Rows.csv", 'application/csv');
            this.excludedRowsSaved = true;
        },
        includeEmptyOption(rules){
            if(rules?.required === true){
                return false;
            }
            if(typeof rules === 'string' && rules.includes("required")){
                return false;
            }
            return true;
        },
        async getNotes(){
            if(this.importNotesGenerator){
                this.importNotes = await this.importNotesGenerator();
            }
        },
        addRowDataToRule(ruleName, rules, item){
            let newRules = $.extend(true, {}, rules);
            //for complex rules, we will make row data avaliable for rule calculations when configured
              if(Array.isArray(ruleName)){
                    ruleName.forEach((rule) => {
                        newRules[rule].rowData = {...item};
                    })
                return newRules;
            }
            newRules[ruleName].rowData = {...item};
            return newRules;
        },
        handleFileLoadError(){
            //due to security restrictions, if the file is modified while loaded,
            //we need the user to re-select the file to give us access to it again.
            //We will reset the control here and prompt them to reselect.  Without resetting
            //the file input control, selecting the same file does not trigger any events.
            this.importCount++;
            this.$toasted.global.app_error('Unable to parse file.  Likely because the file has been modified.  Please reload the file.');
        },
        downloadSample: function() {
            let data = this.fields.map(x => x.key).join(',')

            //grab a sample array to find length, could add a length to configuration if this ends up not being flexable enough
            let sampleLength = this.fields.find(x => x.samples).samples;
            sampleLength.forEach((element, index) => {
                data = data + '\n' + this.getSampleRow(index);
            })
            if(this.sampleNotes){
                data = data + '\n\n\nNotes:\n' + this.sampleNotes.join('\n') + '\n' +
                        '"Do not include notes section, or extra lines in a file that is used for importing data."';
            }
            downloadFileToBrowser(data, this.dataType + "_Sample_Import.csv", 'application/csv');
        },
        getSampleRow(index){
            return this.fields.map(x => {
                if(x.samples && x.samples[index] != undefined){
                    return x.samples[index];
                }
                if(x.dataType){
                    //for dynamic data, grab real values.
                    return this.selectListOptions[x.dataType][Math.min(1,this.selectListOptions[x.dataType].length -1)][x.comparisonProperty || 'text'];
                }
            }).join(',');
        },
        enableEdit(field, item){
            if (this.editAll){
                return true;
            }
            let fieldName =  field.dataType ? field.key + 'Id' : field.key;
            if(field.hasErrorCalculator  && field.hasErrorCalculator(item.originalData[fieldName], item.originalData, true)){
                return true;
            }
            if(field.hasWarningCalculator  && field.hasWarningCalculator(item.originalData[fieldName], item.originalData, true)){
                return true;
            }
            return false;
        },
        fileLoaded(data) {
            this.$emit('input', data);
            this.uploadedData = data;

            //saving originalData before applying formatRowData or user updates so we
            //can export the original values and display them in error messages            
            this.uploadedData.forEach(x => {x.originalData = this.matchDropdownObjects({...x}); });

            this.$nextTick(() => {
                this.validateData();
                this.showTables = true;
            });
        },
        loadingNewFile(){
            this.excludedRows = [];
            this.showTables = false;
        },
        async validateData() {
            try{
                this.isBusy = true;
                this.uploadedData.forEach(r => {this.formatRowData(r);this.validateRow(r)});
                this.validRows = this.uploadedData.filter(x => !x.hasErrors && !x.hasWarnings);
                this.invalidRows = this.uploadedData.filter(x => x.hasErrors);
                this.warningRows = this.uploadedData.filter(x => x.hasWarnings && !x.hasErrors);
                this.validateInvalidForm();
                this.$emit('data-validated');
                await this.getNotes();
            }
            finally{
                this.isBusy = false;
            }
        },

        formatRowData(row){
            //papa parse has the ability to convert string number values to numbers, but I can't find a way
            //to configure it to only do specific columns.
            this.numberProperties.forEach(f =>
                {
                    if( row[f.key] === '') {row[f.key] = null}
                    let numberValue =  parseFloat(row[f.key]);
                    //if the provided value is not number, keep it as is so the user can see and correct the issue.
                    if(!isNaN(numberValue)) {row[f.key] = numberValue}
                }
            );
            this.dateProperties.forEach(f =>
                {
                    //empty strings throw exception when passed to the api for nullable date objects.
                    if( this.isEmpty(row[f.key])) {
                        row[f.key] = null;
                        return;
                    }
                    var parsedDate = moment(row[f.key],                   
                    this.$validDates, true);
                    if(parsedDate.isValid())
                    {
                        //going with toISOString because the moment parsing was being rejected by the server:
                        //The JSON value could not be converted to System.Nullable`1[System.DateTime].
                        row[f.key] = parsedDate.toISOString();
                    }
                    else{
                        //setting it to 'invalid' if not valid, otherwise the datepicker can set it to weird values that we
                        //don't want depending on the input.  For example "Invalid Date 1" loads as 01/01/2001.
                        //Setting it to null will cause the validation to not trip properly.
                        //Issue stems from p-datepicker parsing the date using new Date() which is too flexible and
                        //can pull dates out of strings that don't have data that resembles a valid date.
                        row[f.key] = 'invalid';
                    }
                }
            );
            this.matchDropdownObjects(row);            
        },    

        matchDropdownObjects(row){
            //for elements in the store, make sure they match an option and grab the id.
            this.idProperties.forEach(f =>
                {
                    if(!row[f.key] || row[f.key] === ''){ return;}
                    //make sure the match grabs from the filtered list
                    let filterValue = f.selectOptionsFilter ? f.selectOptionsFilter(row) : null;                    
                    let match = this.selectListOptions[f.dataType]
                        .filter(x => !filterValue || x.filter == filterValue)
                        .find(x => (x[f.comparisonProperty ?? 'text'] + '').toLocaleLowerCase() === (row[f.key] + '')
                        .toLocaleLowerCase());
                    Vue.set(row, `${f.key}Id`, match ? match.value : -1)

                }
            );
            return row;
        },

        setDropDownTextValue(row, selection, field){
            //if an element id has been selected from a drop down, update the text as well.
            row[field.key] = selection[field.comparisonProperty ?? 'text'];
        },
        validateInvalidForm() {
            //Not a fan of relying on a set-timeout here, but there is a lag in the date-picker loading data causing
            //the invalid message to not always appear until revalidate is clicked.  Adding this delay avoids that issue.
            setTimeout(() => {
                this.$nextTick().then(() => {
                    this.$refs.invalidForm[0].validate().then(() => {
                            this.$refs.warningForm[0].validate();
                        });
                });
            }, 300)
        },
        validateRow(row) {
            row.hasErrors = this.fields.some(
                f => f.hasErrorCalculator && f.hasErrorCalculator(row[f.dataType ? f.key + 'Id' : f.key], row)
            );
            row.hasWarnings = this.fields.some(
                f => f.hasWarningCalculator && f.hasWarningCalculator(row[f.dataType ? f.key + 'Id' : f.key], row)
            );
        },
        async submit() {
            this.isBusy = true;

            this.uploadedData.forEach(r => {this.formatRowData(r);this.validateRow(r)});
            if(this.warningRows.length > 0 || (this.excludedRows.length > 0 && !this.excludedRowsSaved)){
                await this.$bvModal.msgBoxOk('A CSV of the problem rows will be downloaded')
                this.downloadExcludedRows();
            }

            try{

                //clone uploaded data so we can apply and submit the warning defaults without losing the values the user actually updated (in case import fails)
                var dataForImport = this.warningDefaultProperties.length > 0 ? this.uploadedData.map(a => ({...a})) : this.uploadedData;
                this.warningDefaultProperties.forEach(f =>
                {
                    //if this.isEmpty(value) or fails warning validaiton then set to warningDefault
                    dataForImport.forEach(row => {
                        let currentValue = row[f.dataType ? f.key + 'Id' : f.key]
                        if(!this.isEmpty(currentValue) && !(f.hasWarningCalculator  &&  f.hasWarningCalculator(currentValue, row))){
                            //has a value without a warning so we don't need to apply default
                            return;
                        }
                        row[f.key] = f.warningDefault;
                        if(f.dataType){
                             row[f.key + 'Id'] =  f.warningDefault
                        }
                    });

                });
                await Axios.post('imports/' + this.dataType, { importData:dataForImport, convertCase: this.convertCase, importMode:this.importMode });
                this.$toasted.global.app_success(`${this.label} imported successfully.`).goAway(5000);
                this.resetImport();
            }
            catch(err){
                this.$emit('upload-failed');
                this.validateData();
            }
            finally{
                this.isBusy = false;
            }
        },
        resetImport(){
            this.unMappedFields = [];
            this.validRows = [];
            this.invalidRows = [];
            this.excludedRows = [];
            this.showTables = false;
            this.importCount++;
            this.$emit('form-reset');
        },
        handleRunNoParse(file){
            this.$emit('run-no-parse', file);
        }
    },

    data() {
        return {
            selectListOptions:{
                brands: [],
                taxItems: [], //tax type (dcc)
                productTypes: [], //used for dcc type
                priceTagTypes: [],
                yesNoOptions: [],
                courses: [],
                states: [],
                terms: [],
                campuses: [],
                courseDepartments: [],
                courseDepartmentsImport: [],
                courseLevels: [],
                openCompletedOptions: [],
                courseRequestBookStatuses: [],
                packageTypes:[],
                bindings:[],
                suppliers:[],
                dcc:[],
                textStatus: [],
                buybackToSubtractOptions: [],
                supplierReturnPolicies: [],
                arAccountActionOptions: [],
                customers: [],
                accounts: [],
                isActiveOptions: [],
                shippingProviders: [],
                shippingLevels: [],
                locations: [],
                addReplaceOptions: [],
                productVariants: [],
                webCatalogs: []
            },
            importNotes:[],
            showTables:false,
            isBusy:false,
            importCount:0,
            unMappedFields: [],
            uploadedData: [], //All rows that have been uploaded (minus rows set to be excluded)
            validRows: [],
            invalidRows: [],
            warningRows: [],
            excludedRows: [],
            excludedRowsSaved: false,
            editAll: false,
            convertCase: true,
            editOptions: [
                { value: false, text: 'Edit Invalid Data' },
                { value: true, text: 'Edit All Data' }
            ]
        };
    }
};
</script>

<style scoped>
::v-deep td {
    min-width: 150px;
    max-width: 300px;
}
::v-deep th {
    min-width: 150px;
    max-width: 300px;
}
::v-deep .invalidForm th:first-child {
    min-width: 50px;
    max-width: 50px;
}
::v-deep .invalidForm td:first-child {
    min-width: 50px;
    max-width: 50px;
}
::v-deep table {
    width: auto;
}
::v-deep .compact-control {
    width:150px;
}
::v-deep .compact-container {
    width:150px;
} 
</style>
