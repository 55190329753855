<template>
    <div class="container-fluid content-wrapper" v-if="invoice">
        <p-form @submit="onSubmit" id="invoice-form">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Invoice</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <b-button type="submit" variant="primary"
                        >Save</b-button
                    >
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="auto" class="mr-auto mb-2 d-flex" v-if="!isNew">
                    <b-dropdown
                        class="mr-2"
                        :text="`Purchase Orders (${allPurchaseOrders.length})`"
                        variant="outline-primary"
                    >
                        <b-dropdown-item
                            v-for="purchaseOrder in allPurchaseOrders"
                            :key="purchaseOrder.purchaseOrderId"
                            @click="
                                loadPurchaseOrder(purchaseOrder.purchaseOrderId)
                            "
                        >
                            {{ purchaseOrder.poNumber }}
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown
                        :text="`Credit Requests (${allReturns.length})`"
                        variant="outline-primary"
                        class="mr-2"
                    >
                        <b-dropdown-item
                            v-for="creditRequest in allReturns"
                            :key="creditRequest.creditRequestId"
                            @click="
                                loadCreditRequest(creditRequest.creditRequestId)
                            "
                        >
                            {{ creditRequest.requestNumber }}
                        </b-dropdown-item>
                    </b-dropdown>
                    <p-button
                        :is-busy="isBusy"
                        variant="outline-primary"
                        type="button"
                        @click="exportInvoice"
                        >Export Invoice</p-button
                    >
                </b-col>
            </b-row>
            <p-card>
                <Invoice :invoice="invoice" :is-busy="isBusy" @date-picker-initialized="e => {loadData()}" />
            </p-card>
            <b-row>
                <b-col class=" mt-3 text-center">
                    <p-button
                        variant="primary"
                        type="submit"
                        form="invoice-form"
                        :is-busy="isBusy"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </p-form>
    </div>
</template>

<script>
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import Invoice from '@/views/PurchaseOrders/Invoice.vue';
import axios from 'axios';
import editPage from '@/components/mixins/EditPage';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';

export default {
    mixins: [NavigationGuard, editPage],
    components: {
        Invoice
    },
    data() {
        return {
            invoice: null,
            isBusy: false
        };
    },
    watch: {
        invoice: NavigationGuard.$watcher
    },
    mounted: function() {
        this.loadData();
    },
    computed: {
        allReturns() {
            let creditRequests = this.invoice.invoiceDetails.flatMap(
                x => x.creditRequestDetails
            );
            return creditRequests
                .filter(
                    (e, i) =>
                        creditRequests.findIndex(
                            a => a.creditRequestId === e.creditRequestId
                        ) === i
                )
                .sort((a, b) => {
                    return a.requestNumber.localeCompare(b.requestNumber);
                });
        },
        allPurchaseOrders() {
            let purchaseOrders = this.invoice.invoiceDetails.map(x => ({
                purchaseOrderId: x.purchaseOrderId,
                poNumber: x.purchaseOrder
            }));
            return purchaseOrders
                .filter(
                    (e, i) =>
                        purchaseOrders.findIndex(
                            a => a.purchaseOrderId === e.purchaseOrderId
                        ) === i
                )
                .sort((a, b) => {
                    return a.poNumber.localeCompare(b.poNumber);
                });
        }
    },
    methods: {
        loadCreditRequest(id) {
            this.$router.push(`/creditrequests/${id}`);
        },
        loadPurchaseOrder(id) {
            this.$router.push(`/purchaseOrders/${id}`);
        },
        onSubmit() {
            this.isBusy = true;
            axios
                .post('invoices', this.invoice)
                .then(response => {
                    if (this.isNew) {
                        this.dataSaved();
                        this.$router.push(
                            '/invoices/' + response.data.invoiceId
                        );
                    }

                    this.invoice = response.data;
                    this.dataLoaded();
                    this.$toasted.global
                        .app_success(
                            `Invoice '${this.invoice.invoiceNumber}' saved successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },

        loadData() {
            let promise = this.isNew
                ? axios.get('invoices/new')
                : axios.get('invoices', { params: { id: this.id } });

            promise.then(resp => {
                this.invoice = resp.data;

                this.dataLoaded();
            });
        },
        exportInvoice() {
            this.isBusy = true;
            axios
                .get('invoices/exportInvoice', {
                    params: { invoiceId: this.id },
                    responseType: 'blob'
                })
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        'Invoice',
                        'application/pdf'
                    );
                    this.$toasted.global
                        .app_success(`Invoice generated successfully.`)
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }
};
</script>
