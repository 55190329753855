<template>
    <p-secure :require-role="[SECURITYROLES.STOREMANAGER]">
        <div v-if="account">
            <p-card>
                <b-row class="ml-2 pl-2 mb-1">
                    <CustomerAccountDetails
                        class="mr-3"
                        buttonLabel="Link Customer"
                        id="new"
                        :customerId="0"
                        :account="account"
                        @result-updated="refreshTable"
                    />

                    <p-button
                        variant="outline-primary"
                        :is-busy="isBusy"
                        @click="unlinkCustomers()"
                    >
                        Unlink
                    </p-button>
                </b-row>
        <p-search-table
            api-destination="accountreceivable"
            search-destination="customersearch"
            ref="searchScreen"
            :filters="filters"
            :fields="fields"
            edit-key="customerId"
            add-bulk-is-active-toggle
            :show-set-to="false"
            :use-bootstrap-selection="true"
            :selectable="true"
            @selected="onSelection"
            @row-selected="onSelection"
            :refreshSearchResults="refreshSearchResults"
        >
           

            <template v-slot:cell(fullName)="{ item }">
                {{ item.lastName }}
            </template>

            <template v-slot:cell(edit)="{ item }">
                <CustomerAccountDetails
                    buttonLabel="Edit"
                    :id="item.customerId.toString()"
                    :customerId="item.customerId"
                    :details="item"
                    :account="account"
                    @result-updated="refreshTable"
                />
            </template>

            <template v-slot:cell(customerId)="{ item }">
                <router-link :to="'/customers/' + item.customerId">
                    {{ item.customerAccount }}
                </router-link>
            </template>
        </p-search-table>
            </p-card>
        </div>
    </p-secure>
</template>

<script>
import { dateFormatter, currencyFormatter } from '../../components/Common/Formatters.js';
import CustomerAccountDetails from './CustomerAccountDetails.vue';
import axios from 'axios';

export default {
    props: {
        customers: Object,
        account: Object
    },
    methods: {
        onSelection(customers) {
            this.selectedCustomers = customers;
        },
        unlinkCustomers() {
            if (this.selectedCustomers.length === 0) {
                this.$bvModal.msgBoxOk(`Please select a customer to unlink.`);
            } else {
                this.$bvModal
                    .msgBoxConfirm(
                        'This action will disable access to the selected customer. Select OK to continue.'
                    )
                    .then(value => {
                        if (value) {
                            this.isBusy = true;

                            const body = {
                                accountId: this.account.accountId,
                                customerIds: this.selectedCustomers.map(
                                    cust => cust.customerId
                                )
                            };

                            axios
                                .post('AccountReceivable/linkunlink', body)
                                .then(() => {
                                    this.isBusy = false;
                                    this.selectedCustomers = [];
                                    this.$refs.searchScreen.refreshSearchResults();
                                })
                                .finally(() => {
                                    this.isBusy = false;
                                });
                        }
                    });
            }
        },
        refreshTable() {
            this.$refs?.searchScreen.refreshSearchResults();
        }
    },
    data() {
        return {
            filterName: 'CustomersFilter',
            selectedCustomers: [],
            isBusy: false,
            filters: {
                automatic: '',
                perPage: 100,
                searchBy: 'All',
                AccountId: this.account.accountId,
                options: ['All', 'Customer Account', 'Last Name', 'Email']
            },
            fields: [
                {
                    label: '',
                    key: 'edit'
                },
                {
                    label: 'Last Name',
                    key: 'lastName',
                    sortable: true
                },
                {
                    label: 'First Name',
                    key: 'firstName'
                },
                {
                    label: 'Customer Account',
                    key: 'customerId',
                    sortable: true,
                },
                {
                    label: 'Email',
                    key: 'email',
                    sortable: true
                },
                {
                    label: 'Start Date',
                    key: 'startDate',
                    sortable: true,
                    formatter: dateFormatter
                },
                {
                    label: 'End Date',
                    key: 'endDate',
                    sortable: true,
                    formatter: dateFormatter
                },
                {
                    label: 'Available Funds',
                    key: 'availableFunds',                    
                    formatter: currencyFormatter,
                    sortable: true,
                    tdClass: 'text-center'
                },
                {
                    label: 'Comment',
                    key: 'comments',
                    tdClass: 'showEllipsis'
                }
            ],
            refreshSearchResults: false
        };
    },
    components: {
        CustomerAccountDetails
    }
};
</script>

<style scoped></style>
