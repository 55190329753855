<template>
  <div role="tablist">
    <b-row  v-if="purchaseOrderId">
      <b-col>
            <p-button
                :is-busy="isBusyInvoice"
                variant="outline-primary"
                type="button"
                @click="exportInvoice"
                >Export Invoice</p-button
            >
      </b-col>
        <b-col class="text-right">
        <p-button class="mr-1 text-right" hidden variant="danger" @click="$emit('delete-invoice')">Delete</p-button>
        <p-button variant="btn btn-primary" class="ml-2" type="submit"  :is-busy="isBusyInvoice" form="purchase-order-form">Save</p-button>
      </b-col>
    </b-row>

  <p-form ref="invoiceForm" :id='formId'>
    <b-card no-body class="mb-1">
      <b-row cols="1" cols-sm="2" cols-xl="4" class="mt-3">
        <b-col>
            <p-input label="Invoice Number"
                      v-model="invoice.invoiceNumber"
                      rules="required|max:100"
                      :disabled="disableEdit"/>
        </b-col>
        <b-col>
          <p-datepicker
            label="Invoice Date"
            rules="required"
            @date-picker-initialized="e => {$emit('date-picker-initialized');}"
            v-model="invoice.invoiceDate"
            :disabled="disableEdit"
          />

        </b-col>
        <b-col>
            <p-datepicker
              label="Due Date"
              rules="required"
              @date-picker-initialized="e => {$emit('date-picker-initialized');}"
              v-model="invoice.dueDate"
              :disabled="disableEdit"
            />
        </b-col>
        <b-col>
            <p-number label="Terms %"
                      v-model="invoice.termPercent"
                      input-type="percent"
                      rules="max_value:100|p-decimal:5,2|min_value:0"
                      :disabled="disableEdit"/>
        </b-col>
        <b-col>
            <p-number label="Days #"
                      input-type="integer"
                      v-model="invoice.termDays"
                      rules="p-integer|min_value:0"
                      :disabled="disableEdit"/>
        </b-col>
        <b-col>
            <p-advanced-select label="Supplier"
                                data-type="suppliers"
                                v-model="invoice.supplierId"
                                rules="required"
                                :disabled="disableEdit"/>
        </b-col>
        <b-col>
            <p-datepicker label="Return Expiration Date"
                          v-model="invoice.returnExpirationDate"
                          @date-picker-initialized="e => {$emit('date-picker-initialized');}"
                          :disabled="disableEdit"/>
        </b-col>
        <b-col>
            <p-number label="Freight"
                      input-type="money"
                      v-model="invoice.totalFreight"
                      rules="min_value:0"
                      :disabled="disableEdit"/>
        </b-col>
      </b-row>
      <b-row cols="1" cols-sm="2" cols-xl="4">
          <b-col>
            Invoice Total: {{ invoiceTotal }}
          </b-col>
          <b-col>
              <p-checkbox label="Paid" v-model="invoice.isPaid" />
          </b-col>
      </b-row>
      <b-row >
        <b-col cols="auto" class="mr-auto mb-2">
          <AddProducts
            :includeFullyInvoicedProducts.sync="includeFullyInvoicedProducts"
            :items="addProductOptions"
            :disable-add-button="disableEdit"
            :purchase-order-id="purchaseOrderId"
            :existing-invoice-details="invoice.invoiceDetails"
            :vendor-id="invoice.supplierId"
            :invoice-id="invoice.invoiceId"
            @selections="addProducts" >
          </AddProducts>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <p-table :items="invoice.invoiceDetails"
                    :enable-row-selection="tableActions.length > 0"
                    :selection-actions="tableActions"
                    :fields="tableDefinition.fields"
                    sort-by="authorTitleOrDescription"
                    >
            <template v-slot:custom-foot="data">
              <b-tr>
                <!-- this empty footer gets rid of an unecessary vertical scrollbar -->
                <b-th colspan="20"><br/></b-th>
              </b-tr>
            </template>
            <template v-slot:cell(purchaseOrder)="{ value, item }">
              <router-link v-if="item.purchaseOrderId != purchaseOrderId" :to="`/purchaseorders/${item.purchaseOrderId}`">
                  <nobr> {{item.purchaseOrder}}</nobr>
              </router-link>
              <template v-else>
                <nobr>{{item.purchaseOrder}}</nobr>
              </template>
            </template>
            <template v-slot:cell(price)="{ value, item }">
                <p-number v-model="item.price"
                          name="price"
                          :disabled="disableEdit"
                          compact-format
                          @change="calculateCost(item)"
                          rules="min_value:0"
                          input-type="money"/>
            </template>
            <template v-slot:cell(discount)="{ value, item }">
                <p-number v-model="item.discount"
                          name="discount"
                          :disabled="disableEdit"
                          compact-format
                          rules="min_value:0|max_value:100"
                          @change="calculateCost(item)"
                          input-type="percent"/>
            </template>
            <template v-slot:cell(cost)="{ value, item }">
                <p-number v-model="item.cost"
                          name="cost"
                          :disabled="disableEdit"
                          compact-format
                          rules="min_value:0"
                          @change="calculatePriceAndDiscount(item)"
                          input-type="money"/>
            </template>
            <template v-slot:cell(margin)="{ value, item }">
                <p-number v-model="item.margin"
                          name="margin"
                          :disabled="disableEdit"
                          compact-format
                          text-only
                          input-type="percent"/>
            </template>
            <template v-slot:cell(retail)="{ value, item }">
                <p-number v-model="item.retail"
                          :disabled="disableEdit"
                          name="retail"
                          compact-format
                          rules='required'
                          text-only
                          input-type="money"/>
            </template>
            <template v-slot:cell(totalPurchaseOrderReceivedQty)="{ value, item }">
                <p-number v-model="item.totalPurchaseOrderReceivedQty"
                          name="TotalReceivedQtY"
                          text-only
                          compact-format
                          input-type="integer"
                          :disabled="disableEdit"/>
            </template>
            <template v-slot:cell(qty)="{ value, item }">
                <p-number v-model="item.qty"
                          name="Invoice Qty"
                          input-type="integer"
                           rules="required|min_value:0"
                           @change="calculateExtCost(item)"
                          compact-format
                          :disabled="disableEdit"/>
            </template>
            <template v-slot:cell(extendedCost)="{ value, item }">  
                <p-number 
                       v-model="item.extendedCost"
                          name="Ext Cost"
                          input-type="money"
                          rules="required|min_value:0"
                          compact-format 
                          :disabled="disableEdit" 
                         /> 
                         
            </template>
              <template v-slot:head(returnQty)="data">
                {{'\u200B'}}
                <div v-if="hasAnyReturns">Return Qty</div>
              </template>
              <template v-slot:cell(returnQty)="{ value, item }">
                <div v-if="hasAnyReturns">
                    <b-dropdown v-if="item.creditRequestDetails.length > 0"
                        :text="integerFormatter(item.creditRequestDetails.reduce((a, i) => a + i.returnQty, 0))"
                        size="xs"
                        variant="light">

                    <b-dropdown-item
                                    v-for="creditRequestDetail in item.creditRequestDetails"
                                    :key="creditRequestDetail.creditRequestId"
                                    @click="loadCreditRequest(creditRequestDetail.creditRequestId)">
                        {{ creditRequestDetail.requestNumber  }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <div class="pr-3" v-else>
                    0
                  </div>
                </div>
              </template>
            <template v-slot:cell(removeDetail)="{ value, item }">
                <b-button class="p-1 ml-1 short-button" :disabled="disableEdit" @click="!disableEdit && confirmDeleteDetail(item)">
                    <BIconTrash :disabled="disableEdit" font-scale="0.9" class="mb-3" icon="trash" ></BIconTrash>
                </b-button>
            </template>
          </p-table>
        </b-col>
      </b-row>
    </b-card>
  </p-form>
  </div>
</template>

<script>
import unionBy from "lodash/unionBy";
import { currencyFormatter,bookVariantFormatter, integerFormatter } from "../../components/Common/Formatters.js";
import { BIconTrash } from "bootstrap-vue";
import pricing from "@/services/PricingCalculations.js"
import axios from "axios";
import AddProducts from '@/views/Invoices/AddProductsModal.vue';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';

export default {

  name: "Invoice",
  props: {
    invoice: [Object],
    isBusy: {
      type: Boolean,
      default: false,
    },
    purchaseOrderId: {
      type: Number,
      default: null,
    },
    purchaseOrderDetails: {
      type: Array,
      default: () => [],
      },
    allRequestDetails: [Array],
  },
  components: {
    BIconTrash,
    AddProducts
  },
  created: function() {
      this.isBusyInvoice = this.isBusy;
  },
  data() {
      return {
        parameters: {},
        includeFullyInvoicedProducts: false,
        isBusyInvoice: {
          type: Boolean,
          default: false,
        },
        tableDefinition: {
        fields: [
          {
            key: "sku",
            label: "SKU",
            sortable: true,
            automaticSearch: true,
          },
          {
            key: "purchaseOrder",
            sortable: true
          },
          {
              key: "termName",
              label: "Term",
              sortable: true
          },
          {
            key: "authorTitleOrDescription",
            label:"Author/Title or Description",
            sortable: true,
          },
          {
            label: 'Author',
            key: 'author',
            sortable: true,
            automaticSearch: true,
            hidden:true
          },
          {
            label: 'Title',
            key: 'title',
            sortable: true,
            automaticSearch: true,
            hidden:true
          },
          {
            label: 'Description',
            key: 'authorTitleOrDescription',
            sortable: true,
            automaticSearch: true,
            hidden:true
        },
          {
            key: "copyright",
            sortable: true,
            label: `Variant 1/${'\u200B'}Copyright`,
          },
          {
            key: "edition",
            sortable: true,
            label: `Variant 2/${'\u200B'}Edition`,
          },
          {
            key: 'isbnCatalog',
            sortable: true,
            label: 'Catalog # / ISBN',
            tdClass: 'noWrap'
        },
        {
            key: 'catalogNumber',
            label: 'Catalog #',
            automaticSearch: true,
            hidden:true
          },
          {
            key: 'isbn',
            label: 'ISBN',
            automaticSearch: true,
            hidden:true
          },
          {
            key: 'bookVariant',
            label: 'Type',
            formatter: bookVariantFormatter,
          },
          {
            key: "barcodes",
            label:'Barcode',
            hidden:true,
            automaticSearch: true,
          },
          {
            key: "price",
            thClass: 'text-center'
          },
          {
            key: "discount",
            thClass: 'text-center'
          },
          {
            key: "cost",
            label: "Invoice Cost",
            thClass: 'text-center'
          },
          {
            key: "margin",
            label: "Margin",
            thClass: 'text-center'
          },
          {
            key: "retail",
            label: "Retail",
            thClass: 'text-center'
          },

            {
                key: 'totalPurchaseOrderReceivedQty',
                label: 'Total Rcvd Qty',
                thClass: 'text-center'
            },
            {
                key: 'qty',
                label: 'Invoice Qty',
                thClass: 'text-center'
            },
            {
                key: 'extendedCost', 
                label: 'Ext Cost',
                formatter:currencyFormatter, 
                thClass: 'text-center',
                tdClass: 'text-right'
            },
            {
                key: 'returnQty',
                label: 'Return Qty',
                thClass: 'text-right',
                tdClass: 'text-right'
            },
            {
                key: 'removeDetail',
                label: ''
            }
                ]
            }
        };
    },
    watch: {
        purchaseOrderDetails: function() {
            this.syncReceivedQty();
        },
        'invoice.isPaid': async function(newVal) {
            if (newVal) {
                if (!(await this.$refs.invoiceForm.validate())) {
                    this.invoice.isPaid = false;
                    this.$toasted.global
                        .app_error(
                            'Please resolve all validation errors on this invoice before marking it as paid.'
                        )
                        .goAway(5000);
                }
            }
        },
        isBusy: {
            handler: function(newVal) {
                this.isBusyInvoice = newVal;
            }
        }
    },
    computed: {
      hasAnyReturns(){
        return this.invoice.invoiceDetails.some(x => x.creditRequestDetails?.length > 0 );
      },

      tableActions() {
          if (this.disableEdit) {
              return [];
          }
          return [
              {
                  label: 'Delete',
                  action: this.confirmDeleteDetails
              }
          ];
        },
        disableEdit() {
            return this.invoice.isPaid;
        },
        addProductOptions() {
            return this.purchaseOrderDetails.filter(
                i =>
                    (this.includeFullyInvoicedProducts ||
                        i.qty - i.totalInvoicedQty > 0) &&
                    !this.invoice.invoiceDetails.some(
                        id => id.sku === i.sku && i.termId === id.termId
                    )
            );
        },
        invoiceTotal() {
          
            let total = this.invoice.invoiceDetails.reduce(
                (a, i) => a + i.extendedCost,
                0
            );
            
            var invoiceTotal = total + (this.invoice.totalFreight ?? 0)

            if (isNaN(invoiceTotal)) return currencyFormatter(0);

            return currencyFormatter(invoiceTotal);
        },
        formId() {
            return `invoice-${this.invoice.invoiceNumber}`;
        },

    },
    mounted: function() {
      axios.get('products/gmparameters').then(resp => {
          this.parameters = resp.data;
      });
      this.syncReceivedQty();
  },
  methods: {
    integerFormatter: integerFormatter,  
    calculateExtCost: function(invoiceDetail) {   
        invoiceDetail.extendedCost = invoiceDetail.qty * invoiceDetail.cost; 
      
    },
    loadCreditRequest(id) {
        this.$router.push(
            `/creditrequests/${id}`
        )
    },
    syncReceivedQty(){
      this.invoice.invoiceDetails.forEach((x) => {
        let pod = this.purchaseOrderDetails.find((p) => p.sku === x.sku && x.termId === p.termId && p.purchaseOrderId === x.purchaseOrderId);
        if(pod){
          x.totalPurchaseOrderReceivedQty = pod.receivedQty;
        }
      });
    },
    calculatePriceAndDiscount: function(invoiceDetail) {
      if(invoiceDetail.discount == null && invoiceDetail.price != null){
        invoiceDetail.price = invoiceDetail.cost;
        this.calculateExtCost(invoiceDetail);
        return;
      }
      invoiceDetail.discount = pricing.discountFromPriceAndCost(invoiceDetail.price, invoiceDetail.cost ?? 0);
      this.calculateExtCost(invoiceDetail);
    },
    calculateCost: function(invoiceDetail) {
      if(invoiceDetail.price == null) { return;}
      invoiceDetail.cost = pricing.costFromPriceAndDiscount(invoiceDetail.price, invoiceDetail.discount ?? 0); 
      this.calculateExtCost(invoiceDetail);
    },

    addProducts(items) {
      items = items.map((x) => ({
        ...x,
        //purchaseOrderDetail is used by credit requests, can probably be removed when that section is reworked to work without POs
        purchaseOrderDetail: x,
        //newly added products don't have returns yet
        creditRequestDetails: [],
        totalPurchaseOrderReceivedQty: x.totalPurchaseOrderReceivedQty || x.receivedQty,
        qty: (x.totalPurchaseOrderReceivedQty || x.receivedQty) - x.totalInvoicedQty, 
        extendedCost:((x.totalPurchaseOrderReceivedQty || x.receivedQty) - x.totalInvoicedQty) * x.cost 
      }));
      this.invoice.invoiceDetails = unionBy(
        items,
        this.invoice.invoiceDetails,
        (x) => x.sku + '-' + (x.termId ?? 0) + '-' + x.purchaseOrderId
      );      
    },

        confirmDeleteDetails(selectedRows) {
          var detailsWithReturns = selectedRows.filter(x => this.invoiceDetailHasReturn(x));

          if(detailsWithReturns.length > 0){
            this.$toasted.global
                    .app_error(
                        `Unable to delete selected product(s) because the following have returns attatched:\n
                        ${detailsWithReturns.map(x => `SKU: ${x.sku}, PO: ${x.purchaseOrder}, Term: ${x.termName} `).join('\n')}`
                    )
                    .goAway(8000);
                return;
          }
            this.$bvModal
                .msgBoxConfirm(
                    'Are you sure you want to remove the selected product(s) from the invoice?'
                )
                .then(value => {
                    if (value) {
                        selectedRows.forEach(x => {
                            this.deleteDetail(x);
                        });
                    }
                });
        },
        invoiceDetailHasReturn(invoiceDetail){
          if(invoiceDetail.creditRequestDetails && invoiceDetail.creditRequestDetails.length > 0){
            return true;
          }
          return this.allRequestDetails && this.allRequestDetails.some(
                p => p.sku === invoiceDetail.sku && p.termId === invoiceDetail.termId
              );
        },
        confirmDeleteDetail(detail) {
            let hasReturnDetail = this.invoiceDetailHasReturn(detail);
            if (hasReturnDetail) {
                this.$toasted.global
                    .app_error(
                        'This product cannot be deleted because it has returns attached to it.'
                    )
                    .goAway(5000);
                return;
            } else {
                this.$bvModal
                    .msgBoxConfirm(
                        'Are you sure you want to remove this product from the invoice?'
                    )
                    .then(value => {
                        if (value) {
                            this.deleteDetail(detail);
                        }
                    });
            }
        },
        deleteDetail(detail) {
            let index = this.invoice.invoiceDetails.indexOf(detail);
            if (index !== -1) {
                this.invoice.invoiceDetails.splice(index, 1);
            }
        },
        exportInvoice() {
            this.isBusyInvoice = true;
            axios
                .get('invoices/exportInvoice', {
                    params: { invoiceId: this.invoice.invoiceId },
                    responseType: 'blob'
                })
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        'Invoice',
                        'application/pdf'
                    );
                    this.$toasted.global
                        .app_success(`Invoice generated successfully.`)
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusyInvoice = false;
                });
        }
    }
};
</script>
<style scoped>
>>> .short-button {
    height: 20px;
}
>>> .noWrap {
    white-space: nowrap;
}
</style>
