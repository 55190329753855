<template>
    <SearchScreen
        title="Product Variant Groups"
        api-destination="productvariantgroup"
        edit-key="productVariantGroupId"
        :filters="filters"
        :fields="fields"
        :filterBy="filterBy"
        :show-set-to="false"
        :use-is-active-checkbox="false"
        add-new-button
    >
        <template v-slot:belowSearch>
            <b-row class=" mt-2">
                <p-checkbox
                    label="Active"
                    class="ml-3"
                    v-model="filters.active"
                />
                <p-checkbox
                    label="Inactive"
                    class="ml-2"
                    v-model="filters.inactive"
                />
            </b-row>
        </template>
    </SearchScreen>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
export default {
    mixins: [SearchStorage],
    components: { SearchScreen },
    data() {
        return {
            filterName: 'ProductVariantGroupFilter',
            isBusy: false,
            selectedWeborders: [],
            filters: {
                automatic: '',
                perPage: 100,
                sortBy: 'groupName',
                sortDesc: true,
                active: true,
                inactive: false
            },
            filterBy: ['All', 'Group Name', 'Group Description'],
            fields: [
                {
                    key: 'groupName',
                    sortable: true,
                    label: 'Group Name'
                },
                {
                    key: 'description',
                    sortable: true,
                    label: 'Group Description'
                },
                {
                    key: 'status',
                    label: 'Status'
                }
            ]
        };
    },
    methods: {}
};
</script>
