<template>
    <SearchScreen
        title="Gift Cards"
        api-destination="giftcards"
        edit-key="giftCardId"
        :filters="filters"
        :filterBy="filterBy"
        :fields="fields"
        add-new-button
        ref="search"
    >
         <template v-slot:actionButtons>
      
                 <router-link to="/giftcards/new" class="btn btn-primary"
                    ><b-icon-plus></b-icon-plus>Add</router-link
                >
                <b-overlay
                    :show="isBusy"
                    rounded
                    opacity="0.5"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block pl-3"
                > 
                    <p-button 
                        variant="outline-primary"
                        type="button"
                        @click="downloadFile"
                        >Export</p-button
                    >
                </b-overlay>
         
        </template>

         <template v-slot:afterSearch>
            <p-select
                class="ml-1 search-filter search-by-select"
                v-model="filters.activeFilter"
                :options="activeFilter"
            />
        </template>
    </SearchScreen>
</template>

<script>
import axios from 'axios';
import SearchScreen from '../../components/SearchScreen.vue';
import { dateFormatter, currencyFormatter } from '../../components/Common/Formatters.js';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'GiftCardsFilter',
            isBusy: false,
            filters: {
                sortBy: 'cardCode',
                automatic: "",
                perPage: 100,
                activeFilter: 'Show Active Only'
            },
            filterBy: [
                'All',
                'Card Code',
                'Customer Name',
                'Customer Email',
                'Customer Account Number'
            ],
            activeFilter: [
                'Show All Statuses',
                'Show Active Only',
                'Show InActive Only'
            ],
            fields: [
                {
                    key: 'cardCode',
                    label: 'Card Code',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    formatter: (value) => {
                        return value ? 'Active' : 'Inactive';
                    },
                    sortable: true
                },
                {
                    key: 'issueDate',
                    label: 'Issue Date',
                    formatter: dateFormatter,
                    sortable: true
                },
                {
                    key: 'balance',
                    label: 'Balance',
                    formatter: currencyFormatter,
                    sortByFormatted: true,
                    sortable: true
                },
                {
                    key: 'customerName',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'customerEmail',
                    label: 'Email',
                    sortable: true
                },
                {
                    key: 'customerPhone',
                    label: 'Phone#',
                    sortable: true
                },
                {
                    key: 'comment',
                    label: 'Comment',
                    tdClass: 'showEllipsis',
                    sortable: true
                }
            ]
        };
    },
    computed: {

    },
    mounted: async function() {
    },
    methods: {
         async downloadFile() {
            let apiUrl = '/giftcards/exportgiftcards'; 
            this.isBusy = true;
            axios
                 .get(apiUrl, { 
                    responseType: 'blob'
                })
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        `Gift Card Report`,  
                         'application/pdf'
                          
                    );
                    this.$toasted.global
                        .app_success(
                            `Gift Card Report generated successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    },

    components: {
        SearchScreen
    }
};
</script>

<style scoped lang="scss">
.search-filter /deep/ .custom-select {
    height: 43px;
}
.search-filters {
    margin-bottom: 10px;
}
</style>
