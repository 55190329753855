<template>
    <p-form @submit="onSubmit">
        <div class="container-fluid content-wrapper" v-if="storeSetupData">
            <b-row class="content-heading">
                <b-col>
                    <div>Store Setup</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <b-button type="submit" variant="primary">Save</b-button>
                </b-col>
            </b-row>
            <b-tabs justified>
                <b-tab title="Contact Information">
                    <p-card>
                        <b-row cols="1" cols-sm="2" cols-xl="4">
                            <b-col>
                                <p-input
                                    label="Name"
                                    v-model="storeSetupData.locations.name"
                                    rules="max:128|required"
                                />
                            </b-col>
                            <b-col>
                                <p-input
                                    label="Short Name"
                                    v-model="storeSetupData.locations.shortName"
                                    rules="max:24|required"
                                />
                            </b-col>
                            <b-col>
                                <p-input
                                    label="Email"
                                    v-model="storeSetupData.locations.email"
                                    rules="max:254|email"
                                />
                            </b-col>
                            <b-col>
                                <p-input
                                    label="URL"
                                    v-model="storeSetupData.locations.url"
                                    rules="max:255"
                                />
                            </b-col>
                            <b-col>
                                <p-input
                                    label="Abbreviation"
                                    v-model="
                                        storeSetupData.locations.abbreviation
                                    "
                                    rules="max:4|required"
                                />
                            </b-col>
                            <b-col>
                                <p-number
                                    label="Store Number"
                                    input-type="integer"
                                    v-model.number="
                                        storeSetupData.locations.storeNumber
                                    "
                                    rules="required"
                                />
                            </b-col>
                            <b-col>
                                <p-input
                                    label="SAN"
                                    v-model="storeSetupData.locations.san"
                                    rules="max:255"
                                />
                            </b-col>
                            <b-col>
                                <b-form-row>
                                    <b-col cols="7">
                                        <p-phone-number
                                            label="Phone Number"
                                            v-model="
                                                storeSetupData.locations
                                                    .phoneNumber.number
                                            "
                                            rules="max:16"
                                        />
                                    </b-col>
                                    <b-col cols="5">
                                        <p-input
                                            label="Extension"
                                            v-model="
                                                storeSetupData.locations
                                                    .phoneNumber.extension
                                            "
                                            rules="max:6"
                                        />
                                    </b-col>
                                </b-form-row>
                            </b-col>
                        </b-row>
                    </p-card>
                    <p-card>
                        <b-row>
                            <b-col name="shippingAddressSection">
                                <span class="text-center"
                                    ><h5>Shipping</h5></span
                                >
                                <p-address
                                    v-model="
                                        storeSetupData.locations.shippingAddress
                                    "
                                    :fullWidth="true"
                                >
                                </p-address>
                            </b-col>
                            <b-col name="billingAddressSection">
                                <span class="text-center"
                                    ><h5>Billing</h5></span
                                >
                                <p-address
                                    v-model="
                                        storeSetupData.locations.billingAddress
                                    "
                                    :same-as-address="
                                        storeSetupData.locations.shippingAddress
                                    "
                                    :fullWidth="true"
                                />
                            </b-col>
                        </b-row>
                    </p-card>
                </b-tab>
                <b-tab title="Parameters">
                    <p-card title="System Settings">
                        <b-row cols="1" cols-sm="2" cols-xl="4">
                            <b-col>
                                <p-select
                                    label="Location"
                                    v-model="
                                        storeSetupData.parameters
                                            .singleLocationId
                                    "
                                    rules="required"
                                    data-type="locations"
                                />
                            </b-col>
                            <b-col>
                                <p-select
                                    label="Time Zone"
                                    v-model="storeSetupData.parameters.timeZone"
                                    :options="
                                        storeSetupData.parameters.timeZones
                                    "
                                    rules="required"
                                />
                            </b-col>
                        </b-row>
                    </p-card>
                    <p-card title="Book Pricing Settings">
                        <b-row cols="1" cols-sm="2" cols-xl="4">
                            <b-col>
                                <p-select
                                    label="Currency Rounding (Up)"
                                    v-model="
                                        storeSetupData.parameters
                                            .currencyRounding
                                    "
                                    :options="options"
                                    rules="required"
                                    text-field="value"
                                />
                            </b-col>
                        </b-row>
                        <b-row cols="1" cols-sm="2" cols-xl="4">
                            <b-col>
                                <p-number
                                    label="New Price - Net Price Margin"
                                    input-type="percent"
                                    v-model="
                                        storeSetupData.parameters
                                            .textbooksNewPriceNetPriceMargin
                                    "
                                    rules="required|p-decimal:8,2|max_value:99.99"
                                />
                            </b-col>
                            <b-col>
                                <p-number
                                    label="Used Retail Price - Percent of New Price"
                                    input-type="percent"
                                    v-model="
                                        storeSetupData.parameters
                                            .textbooksUsedRetailPricePercentOfNewPrice
                                    "
                                    rules="required|p-decimal:8,2"
                                />
                            </b-col>
                            <b-col>
                                <p-number
                                    label="Used Cost - Percent of New Price"
                                    input-type="percent"
                                    v-model="
                                        storeSetupData.parameters
                                            .textbooksUsedCostPercentOfNewPrice
                                    "
                                    rules="required|p-decimal:8,2"
                                />
                            </b-col>
                            <b-col>
                                <p-select
                                    label="Update Used Prices from New"
                                    :options="yesNoOptions"
                                    v-model="
                                        storeSetupData.parameters
                                            .textbooksUpdateUsedPricesFromNew
                                    "
                                    mode="lazy"
                                    rules="required"
                                />
                            </b-col>
                        </b-row>
                    </p-card>
                    <p-card title="Course Materials Settings">
                        <b-row cols="1" cols-sm="2" cols-xl="3">
                            <b-col>
                                <p-select
                                    label="Shelf Tag Template"
                                    v-model="
                                        storeSetupData.parameters
                                            .shelfTagTemplateOption
                                    "
                                    :options="shelfTagTemplateOptions"
                                    text-field="value"
                                />
                            </b-col>
                            <b-col>
                                 <p-select
                                        label="Rental Agreement"
                                        data-type="rentalAgreement"
                                        v-model="rentalAgreementId"
                                        :disabled="storeSetupData.parameters.rentalAgreementId != null && storeSetupData.parameters.disableRentalAgreement"
                                    />
                                    <div v-if="noRentalAgreement" style="color:red;">
                                        Rental Agreement must be set in Thunder Admin.
                                    </div>
                                    <div v-if="storeSetupData.parameters.rentalAgreementId != null && storeSetupData.parameters.disableRentalAgreement"  style="color:red;">
                                        Cannot change rental agreement while active rentals exist.
                                    </div>
                             </b-col>
                             <b-col>
                                <p-select dataType="taxItems"
                                v-model="
                                        storeSetupData.parameters
                                            .rentalTaxItemId
                                    "
                                    label="Rental Tax Type" />
                              </b-col>
                        </b-row>
                    </p-card>
                    <p-card title="Book Default DCC Settings">
                        <b-row cols="1" cols-xl="3">
                            <b-col>
                                <p-advanced-select
                                    label="New Book DCC"
                                    data-type="dcc"
                                    v-model="
                                        storeSetupData.parameters
                                            .textbooksDefaultNewDCCId
                                    "
                                    :filter="2"
                                />
                            </b-col>
                            <b-col>
                                <p-advanced-select
                                    label="Used Book DCC"
                                    data-type="dcc"
                                    v-model="
                                        storeSetupData.parameters
                                            .textbooksDefaultUsedDCCId
                                    "
                                    :filter="2"
                                />
                            </b-col>
                            <b-col>
                                <p-advanced-select
                                    label="Trade Book DCC"
                                    data-type="dcc"
                                    v-model="
                                        storeSetupData.parameters
                                            .textbooksDefaultTradeDCCId
                                    "
                                    add-empty-option
                                />
                            </b-col>
                        </b-row>
                    </p-card>
                    <p-card>
                        <b-row>
                            <b-col cols="auto">
                                <h4>Digital Content</h4>
                            </b-col>
                            <b-col cols="auto" class="ml-auto">
                                <p-checkbox
                                        :use-switch="true"
                                        v-model="storeSetupData.parameters.enableDigitalContent"
                                        class="text-right"
                                        label="Enable"
                                        size="lg"
                                >
                                </p-checkbox>
                            </b-col>
                        </b-row>
                        <div v-if="storeSetupData.parameters.enableDigitalContent">
                        <b-row cols="1" cols-xl="3">
                             <b-col>
                                <p-advanced-select
                                    label="Digital Book DCC"
                                    data-type="dcc"
                                    v-model="
                                        storeSetupData.parameters
                                            .textbooksDefaultDigitalDCCId
                                    "
                                />
                            </b-col>
                            <b-col>
                                <p-advanced-select
                                    label="Digital Receipt Info"
                                    data-type="digitalAgreements"
                                    v-model="
                                        storeSetupData.parameters
                                            .digitalAgreementId
                                    "
                                />
                            </b-col>
                        </b-row>
                        <b-row cols="1" cols-xl="3">
                             <b-col>
                                <p-checkbox label="Auto-Adopt Digital Titles"
                                            v-model="storeSetupData.parameters
                                            .autoAdoptDigitalTitles">
                                </p-checkbox>
                            </b-col>
                        </b-row>
                        </div>
                    </p-card>
                    <p-card title="Point Of Sale">
                        <b-row cols="1" cols-sm="2" cols-xl="6">
                            <b-col>
                                <p-input
                                    label="Store Open"
                                    type="time"
                                    rules="required"
                                    v-model="
                                        storeSetupData.parameters.storeOpen
                                    "
                                />
                            </b-col>
                            <b-col>
                                <p-input
                                    label="Store Close"
                                    type="time"
                                    rules="required"
                                    v-model="
                                        storeSetupData.parameters.storeClose
                                    "
                                />
                            </b-col>
                        </b-row>
                    </p-card>
                    <p-card title="POS Tax Names">
                        <b-row>
                            <b-col cols="4" md="3" lg="2">
                                <h5>Tax Rate Name</h5>
                            </b-col>
                            <b-col cols="8" md="9" lg="10">
                                <h5>Custom Tax Name</h5>
                            </b-col>
                        </b-row>
                        <template v-for="(item, index) in taxRates">
                            <b-row :key="index + 'row'">
                                <b-col cols="4" md="3" lg="2" class="pr-5">
                                    Tax Rate {{ index + 1 }}
                                </b-col>
                                <b-col cols="8" md="9" lg="10">
                                    <p-input
                                        :name="`TaxName${index + 1}`"
                                        rules="required|max:80"
                                        v-model="
                                            storeSetupData.parameters[item]
                                        "
                                    />
                                </b-col>
                            </b-row>
                        </template>
                    </p-card>
                </b-tab>
            </b-tabs>
            <b-row class="mt-4">
                <b-col class="mb-3 text-center">
                    <p-button
                        variant="primary"
                        @click="cancelEdit"
                        class="pr-2"
                        :is-busy="isBusy"
                        >Cancel</p-button
                    >
                    <b-button variant="primary" type="submit">Save</b-button>
                </b-col>
            </b-row>
        </div>
    </p-form>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import StoreListManager from '@/components/mixins/StoreListManager.js';
import EditPage from '@/components/mixins/EditPage';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    mixins: [NavigationGuard, StoreListManager, EditPage],
    props: {
        id: String
    },
    data() {
        return {
            storeSetupData: null,
            isBusy: false,
            oldCurrencyRounding: null,
            options: [
                { value: '0.01' },
                { value: '0.05' },
                { value: '0.10' },
                { value: '0.25' },
                { value: '0.50' },
                { value: '1.00' }
            ],
            yesNoOptions: [
                { value: 'true', text: 'Yes' },
                { value: 'false', text: 'No' }
            ],
            selectListOptions: {
                taxRateNames: []
            },
            shelfTagTemplateOptions: [
                { value: 'Portrait-1x3 (3 shelf tags)' },
                { value: 'Portrait-2x3 (6 shelf tags)' },
                { value: 'Landscape-2x2 (4 shelf tags)' }
            ],
            rentalAgreementId:null
        };
    },
    watch: {
        storeSetupData: NavigationGuard.$watcher
    },
    computed: {
        taxRates() {
            return this.selectListOptions.taxRateNames?.map(
                x => x.value[0].toLowerCase() + x.value.slice(1)
            );
        },
        noRentalAgreement()
        {
            return this.rentalAgreements.length <= 0;
        }
    },
    methods: {
        async loadData() {
            let locationId = await this.getSelectedLocationId();
            this.rentalAgreements = await selectListOptionsDataContext.getStoreDropdownData(
                'rentalAgreement'
            );
            axios.get('storesetup', {
                params: { locationId: locationId }
            }).then(resp => {
                this.storeSetupData = resp.data;
                this.rentalAgreementId = this.storeSetupData.parameters.rentalAgreementId;
                this.oldCurrencyRounding = this.storeSetupData.parameters.currencyRounding;
                this.dataLoaded();
            });
        },
        onSubmit() {
            if (
                this.oldCurrencyRounding &&
                this.storeSetupData.parameters.currencyRounding !==
                    this.oldCurrencyRounding && this.storeSetupData.parameters.currencyRounding != '0.01'
            ) {
                this.$bvModal
                    .msgBoxConfirm(
                        'Do you want to update all retail prices and margins to reflect the new rounding method?',
                        {
                            okTitle: 'YES',
                            cancelTitle: 'NO',
                            noCloseOnBackdrop: true
                        }
                    )
                    .then(value => {
                        this.storeSetupData.parameters.isUpdateAllRetailPricesAndMargins = value;
                        this.postStoreSetup();
                    });
            } else {
                this.postStoreSetup();
            }
        },
        postStoreSetup() {
            this.storeSetupData.parameters.rentalAgreementId = this.rentalAgreementId;
            axios.post('storesetup', this.storeSetupData).then(() => {
                this.dataSaved();
                this.oldCurrencyRounding = this.storeSetupData.parameters.currencyRounding;
                this.$toasted.global
                    .app_success('Store setup saved successfully.')
                    .goAway(5000);
            });
        },
        cancelEdit() {
            this.$router.push('/home');
        }
    },
    mounted: function() {
        this.loadData();
    }
};
</script>
